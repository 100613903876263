.preview_wrap {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 40px;
  align-items: center;
  min-height: 50px;
  border: 1px solid #d9d9d9;
  padding: 5px;
  border-radius: 4px;
}

.preview_box {
  display: flex;
  align-items: center;
  gap: 10px;
}

.image {
  width: 48px;
  max-width: 100%;
}

.name {
  color: var(--clr-blue);
  text-decoration: none;
  line-height: 18px;
}

a.name:hover {
  text-decoration: underline;
}
