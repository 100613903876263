.dialog {
  max-width: 380px;
}

.content {
  padding: 30px;
}

.title {
  border-bottom: 1px solid rgba(48, 48, 48, 0.12);
  font-weight: bold;
  color: var(--clr-black-light);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text {
  padding-top: 30px;
  padding-bottom: 15px;
  font-size: var(--text-size-extra-small);
  color: var(--clr-grey-dark);
}

.dialog,
.input fieldset,
.button {
  border-radius: 5px;
}

.input input {
  padding: 10px 14px;
}

.checkbox {
  color: var(--clr-grey-light);
  padding: 5px 8px 5px 0;
}

.label {
  margin: 20px 0;
}

.button {
  height: 40px;
  box-shadow: unset;
}

.button_later {
  margin-top: 8px;
  color: var(--clr-black-light);
}

.close_btn {
  position: relative;
  width: 32px;
  height: 32px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.close_btn:hover {
  opacity: 0.7;
}

.close_btn::after,
.close_btn::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  height: 18px;
  background-color: #fff;
  border-radius: 3px;
  background-color: #404040;
}

.close_btn::before {
  transform: translateY(-50%) translateX(-50%) rotate(45deg);
}

.close_btn::after {
  transform: translateY(-50%) translateX(-50%) rotate(-45deg);
}

@media screen and (max-width: 768px) {
  .content {
    padding: 20px;
  }
}

@media screen and (max-width: 414px) {
  .dialog {
    border-radius: unset;
    max-width: 100%;
  }
}
