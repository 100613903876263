.audio_list {
  margin-top: 15px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  height: 280px;
  overflow-y: auto;
}

.title {
  font-weight: bold;
  color: var(--clr-black-light);
  padding: 15px 15px 7px;
}

.audio_list_item {
  padding: 15px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.audio_list_item:not(:last-child) {
  border-bottom: 1px solid rgba(51, 51, 51, 0.08);
}

.audio_list_item:nth-child(2n) {
  background: rgba(51, 51, 51, 0.04);
}

.audio_list_box {
  display: flex;
  justify-content: space-between;
}

.hidden_slider {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  z-index: -1;
}

.active .hidden_slider {
  z-index: unset;
}

.active .progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(82, 133, 204, 0.35);
}

.audio_list_item.active {
  background: rgba(82, 133, 204, 0.14);
}

.active .text {
  color: #5285cc;
}

.active .num {
  font-weight: bold;
  transform: translateX(30px);
}

.text {
  color: var(--clr-black);
}

.equalizer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 17px;
  height: 15px;
}

.bar {
  width: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #5285cc;
  border-radius: 3px;
  transition: height 0.4s ease;
  animation: none;
  animation: bar 0.4s 0s both alternate ease infinite;
  animation-play-state: paused;
  display: none;
}

.equalizer.animate .bar {
  animation-play-state: running;
}

.active .bar {
  display: block;
}

.bar_1 {
  animation-delay: 0.2s;
}

.bar_2 {
  transform: translateX(5px);
  animation-delay: 0.4s;
}

.bar_3 {
  transform: translateX(10px);
  animation-delay: 0.6s;
}

@keyframes bar {
  0% {
    height: 3px;
  }

  100% {
    height: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .audio_list {
    margin-top: 12px;
    box-shadow: 0px 2px 5px rgba(48, 48, 48, 0.05);
  }
}

@media screen and (max-width: 768px) {
  .audio_list {
    margin: 0 -15px;
  }
}
