.box {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
}

.show {
  display: flex;
}
