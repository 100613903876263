.title {
  border-bottom: 1px solid rgba(48, 48, 48, 0.12);
  font-weight: bold;
  color: var(--clr-black-light);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.close_btn {
  position: relative;
  width: 32px;
  height: 32px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.close_btn:hover {
  opacity: 0.7;
}

.close_btn::after,
.close_btn::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  height: 18px;
  background-color: #fff;
  border-radius: 3px;
  background-color: #404040;
}

.close_btn::before {
  transform: translateY(-50%) translateX(-50%) rotate(45deg);
}

.close_btn::after {
  transform: translateY(-50%) translateX(-50%) rotate(-45deg);
}

.content {
  padding: 25px;
  letter-spacing: var(--letter-spacing-base);
  line-height: 21px;
  color: var(--clr-black-light);
}

.text {
  padding: 10px 0;
}

.parent,
.child {
  list-style-type: none;
  counter-reset: item;
  padding: 0;
  margin: 0;
}

.content ol li {
  display: table;
  counter-increment: item;
  margin-block-end: 1em;
}

.content ol li:before {
  content: counters(item, '.') '. ';
  display: table-cell;
  padding-right: 0.5em;
}

.content .child li {
  margin: 0;
}

.child li:before {
  content: counters(item, '.') ' ';
}
