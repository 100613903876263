.audio_player {
  padding: 16px;
  position: relative;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  /* box-shadow: 0 0px 8px rgba(0, 0, 0, 0.12), 0 5px 10px rgba(0, 0, 0, 0.12); */
}

/* .audio_player::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -8px;
  width: 100%;
  height: 15px;
  background: linear-gradient(-45deg, transparent 53%, #fff 47%),
    linear-gradient(45deg, transparent 53%, #fff 47%),
    linear-gradient(-45deg, transparent 50%, #fff 50%),
    linear-gradient(45deg, transparent 50%, #fff 50%);
  background-size: 15px 15px;
  background-repeat: repeat-x;
} */

.divider {
  width: 100%;
  height: 1px;
  background-color: rgba(51, 51, 51, 0.12);
  margin: 14px 0;
}

@media screen and (max-width: 1024px) {
  .audio_player {
    box-shadow: 0px 2px 5px rgba(48, 48, 48, 0.05);
  }

  .audio_player::after {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .audio_player {
    margin: 0 -15px;
  }
}
