.toolbar {
  background-color: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  padding: 12px 18px;
  margin-bottom: 8px;
}

.active_filters {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.mobile_filter {
  border-radius: 3px;
  background-color: var(--clr-blue);
  border: none;
  padding: 6px 14px;
  margin-left: auto;
  display: none;
}

.active_filter {
  position: relative;
  text-decoration: none;
  padding: 8px 30px 8px 12px;
  font-size: var(--text-size-base);
  color: var(--clr-black);
  background-color: var(--clr-grey-dim-light);
  border-radius: 5px;
  cursor: pointer;
}

.active_filter::after,
.active_filter::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 14px;
  height: 12px;
  width: 2px;
  background-color: var(--clr-blue);
}

.active_filter::after {
  transform: translateY(-50%) rotate(-45deg);
}

.active_filter::before {
  transform: translateY(-50%) rotate(45deg);
}

.active_filter:hover {
  color: var(--clr-black-light);
  text-decoration: line-through;
}

.active_filter:hover::after,
.active_filter:hover::before {
  box-shadow: 0 0 0 0.25px var(--clr-blue);
}

.clear_button {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0 8px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--clr-blue);
  font-size: var(--text-size-base);
  height: 32px;
}

.clear_button::after {
  content: '';
  position: absolute;
  right: 8px;
  bottom: 5px;
  left: 8px;
  border-bottom: 1px dashed var(--clr-blue);
}

.clear_button:hover {
  opacity: 0.8;
}

@media screen and (max-width: 768px) {
  .active_filters {
    display: none;
  }

  .mobile_filter {
    display: flex;
  }

  .toolbar {
    margin: 0 -15px 8px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  }
}
