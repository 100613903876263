.not_found {
  text-align: center;
  margin: 50px auto;
  border: 7px solid #f0f0f0;
  padding: 35px;
}

.not_found h1 {
  margin-top: 20px;
  font-size: var(--text-size-huge);
  color: var(--clr-black);
}

.not_found p {
  font-weight: bold;
}

.not_found p a {
  font-weight: bold;
  color: var(--clr-blue);
}

.not_found p a:hover {
  color: #7fb5ff;
}
