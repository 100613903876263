.wrap {
  padding-top: 56px;
  height: 100%;
}

.content {
  height: 100%;
  padding: 20px;
  margin: auto;
}

.content_shift {
  padding-left: var(--sidebar-width);
}

@media screen and (min-width: 600px) {
  .wrap {
    padding-top: 64px;
  }
}
