.container {
  max-width: 550px;
  margin: 100px auto;
}

.paper {
  padding: 40px 60px;
}

.title {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
  color: var(--clr-black-light);
}

.text_field {
  margin-top: 20px;
}

.button {
  display: block;
  padding-left: 40px;
  padding-right: 40px;
  margin: 20px auto 0;
}

.error {
  color: var(--clr-red);
  margin-top: 15px;
}

@media screen and (max-width: 768px) {
  .container {
    margin: 50px auto;
  }

  .paper {
    padding: 40px 20px;
  }
}
