@import url('./variables.css');

* {
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  padding: 0;
  margin: 0;
  background-color: var(--clr-blue-light);
  color: var(--clr-grey-dark);
  font-size: var(--text-size-base);
}

img {
  max-width: 100%;
}

.container {
  max-width: var(--width-container);
  margin: auto;
  padding: 0 15px;
}

.nav-link.active .nav-title span,
.nav-link.active .nav-icon {
  color: var(--clr-red);
}

.button-indents {
  margin: 10px 0 20px;
}

.buttons-box {
  display: flex;
  gap: 8px;
}

.color-red {
  color: var(--clr-red);
}

.color-green {
  color: var(--clr-green);
}

.d-none {
  display: none;
}

button.Mui-selected {
  color: var(--clr-black-light);
}

@media screen and (max-width: 768px) {
  .modal-open {
    overflow: hidden;
  }
}
