.card {
  display: grid;
  grid-template-columns: 180px 1fr 1fr 1fr;
  grid-gap: 0 16px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  margin-bottom: 4px;
  text-decoration: none;
  color: inherit;
  transition: box-shadow 0.3s;
  cursor: pointer;
}

.preview {
  grid-row: 1/4;
}

.links,
.info,
.description {
  grid-column: 2/5;
}

.genre {
  color: var(--clr-grey-dark);
  letter-spacing: var(--letter-spacing-base);
  text-decoration: none;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 11px;
}

.title {
  color: var(--clr-black);
  font-size: var(--text-size-large);
  letter-spacing: var(--letter-spacing-base);
  text-decoration: none;
}

.description {
  line-height: 22px;
  letter-spacing: var(--letter-spacing-base);
  padding-bottom: 5px;
}

.icon {
  font-size: 16px;
  color: var(--clr-grey);
}

.info {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 16px;
}

.caption {
  display: flex;
  gap: 6px;
  align-items: center;
}

.caption span {
  font-size: var(--text-size-extra-small);
  color: var(--clr-black);
}

.card:hover {
  outline: 1px solid var(--clr-grey-dim-light);
  box-shadow: unset;
}

@media screen and (max-width: 768px) {
  .card {
    grid-template-columns: 128px 1fr;
    margin: 0 -15px 4px;
    grid-gap: 16px;
  }

  .title {
    font-size: var(--text-size-base);
    margin: 12px 0 0;
  }

  .info {
    flex-direction: column;
    align-items: flex-start;
    gap: 13px;
  }

  .preview {
    grid-row: 1/3;
  }

  .links,
  .info {
    grid-column: 2/3;
  }

  .genre {
    font-size: var(--text-size-extra-small);
    margin-bottom: 16px;
  }

  .description {
    order: 1;
    grid-column: 1/3;
    font-size: var(--text-size-small);
    padding-bottom: 0;
  }

  .preview img {
    height: 100%;
  }
}
