.catalog {
  margin: 30px 0 10px;
}

.grid {
  display: grid;
  grid-template-columns: 236px 1fr;
  grid-gap: 10px;
}

.pagination {
  margin: 24px -15px;
  display: flex;
  justify-content: center;
}

.pagination button {
  font-size: var(--text-size-base);
  color: var(--clr-blue);
}

@media screen and (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
    grid-gap: 4px;
  }
}
