.filters {
  padding: 0 2px;
}

.header,
.footer {
  position: sticky;
  display: flex;
  justify-content: space-between;
  height: 56px;
  background-color: #fff;
  display: none;
}

.header {
  padding: 16px;
  align-items: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
}

.title {
  margin: 0;
  font-size: var(--text-size-large);
  color: var(--clr-black);
  font-weight: normal;
}

.close_btn {
  position: relative;
  width: 32px;
  height: 32px;
  border: none;
  background-color: transparent;
}

.close_btn::after,
.close_btn::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  height: 18px;
  background-color: #fff;
  border-radius: 3px;
  background-color: #404040;
}

.close_btn::before {
  transform: translateY(-50%) translateX(-50%) rotate(45deg);
}

.close_btn::after {
  transform: translateY(-50%) translateX(-50%) rotate(-45deg);
}

.footer_btn {
  flex-grow: 1;
  max-width: 50%;
  text-transform: uppercase;
  background-color: #efefef;
  color: var(--clr-black);
  font-size: var(--text-size-base);
  border: none;
  letter-spacing: 0.32px;
}

.blue_btn {
  color: #fff;
  background-color: var(--clr-blue);
}

.blue_btn:disabled {
  background: rgba(82, 133, 204, 0.6);
}

.active_filters {
  padding: 0 21px 0 12px;
  margin-bottom: 20px;
  display: none;
}

.active_filters_title {
  margin: 14px 0 16px;
  font-weight: bold;
  letter-spacing: 0.32px;
  font-size: var(--text-size-small);
  color: var(--clr-black);
}

.active_filters_grid {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

@media screen and (max-width: 768px) {
  .active_filters {
    display: block;
  }

  .filters {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s;
  }

  .filters.show {
    opacity: 1;
    visibility: visible;
  }

  .content {
    background-color: #fff;
    padding: 4px 0;
    overscroll-behavior: contain;
    overflow-y: auto;
    height: calc(100% - 104px);
  }

  .header,
  .footer {
    display: flex;
  }
}
