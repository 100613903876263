.container {
  position: relative;
}

.wrap:hover .select,
.active .select {
  border-color: #1677ff;
}

.select {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100px;
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.image img {
  max-width: 100%;
  height: 100%;
  max-height: 300px;
}

.drag_element {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
