.filter {
  background-color: transparent;
  box-shadow: unset;
  padding-bottom: 5px;
}

.filter:not(:last-child) {
  border-bottom: 0.5px solid rgba(51, 51, 51, 0.12);
}

.filter::before {
  display: none;
}

.title {
  color: var(--clr-black);
  font-size: var(--text-size-medium);
  line-height: 20px;
  font-weight: 700;
}

.details {
  padding: 0 8px 8px;
}

.checkbox {
  color: var(--clr-grey-light);
  padding: 0 5px 0px 4px;
}

.label {
  margin-right: 0;
  display: flex;
}

.label:hover {
  background-color: var(--clr-grey-dim-light);
}

.text {
  letter-spacing: var(--letter-spacing-base);
}

.text i {
  font-style: normal;
  color: #929292;
}

.icon {
  color: var(--clr-grey);
  font-size: 28px;
}

@media screen and (max-width: 768px) {
  .filter {
    padding: 0 20px 0 16px;
  }

  .details {
    display: flex;
    flex-direction: column;
    padding: 0 0 8px;
  }

  .title {
    flex-direction: row;
  }

  .title div {
    margin-left: 0 !important;
  }

  .label {
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 0;
  }

  .label i {
    order: -1;
  }

  .checkbox {
    margin-right: -9px;
    padding: 0 9px 8px 4px;
  }

  .icon {
    margin-left: -4px;
  }
}
