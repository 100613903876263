.header {
  background-color: var(--clr-black);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  gap: 8px;
}

.wrap {
  display: flex;
  align-items: center;
  gap: 10px;
}

.btn {
  cursor: pointer;
  background-color: transparent;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: background-color 0.3s ease-out;
}

.btn:hover {
  background-color: #414141;
}

.icon {
  color: #fff;
  font-size: 32px;
  cursor: pointer;
  transition: opacity 0.3s;
}

.popover {
  margin-top: 5px;
  border-radius: 5px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12);
}

.popover_box {
  padding: 10px 0;
}

.expiration_date {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #fff;
}

.expiration_date svg {
  font-size: 17px;
}

.logout {
  display: flex;
  align-items: center;
  gap: 6px;
  width: 150px;
  padding: 10px 15px;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.popover_expiration_date,
.popover_listeners {
  padding: 15px;
}

.popover_listeners {
  display: flex;
  align-items: center;
  gap: 5px;
}

.info_text {
  color: #fff;
  position: relative;
}

.info_text .spinner {
  position: absolute;
  margin-left: 5px;
  top: -2px;
  color: #fff;
}

.logo_box {
  display: flex;
  align-items: center;
  gap: 15px;
}

.logo_box a svg {
  max-width: 100%;
}

@media screen and (max-width: 768px) {
  .logo_box {
    gap: 10px;
    flex-grow: 1;
    justify-content: space-between;
  }

  .box {
    gap: 0;
  }

  .wrap {
    gap: 5px;
  }
}
