.clear_button {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0 8px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--clr-blue);
  font-size: var(--text-size-base);
  height: 32px;
}

.clear_button::after {
  content: '';
  position: absolute;
  right: 8px;
  bottom: 5px;
  left: 8px;
  border-bottom: 1px dashed var(--clr-blue);
}

.clear_button:hover {
  opacity: 0.8;
}
