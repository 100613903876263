.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--clr-blue);
  width: max-content;
  transform: translateX(-7px);
  margin-bottom: 20px;
}

.link svg {
  transition: transform 0.3s;
}

.link:hover svg {
  transform: translateX(-5px);
}
