.error_message {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  padding: 12px 18px;
  background-color: #fff;
  margin: 10px auto;
  color: var(--clr-black);
  max-width: var(--width-container);
}

@media screen and (max-width: 768px) {
  .error_message {
    margin: 0 -15px;
    padding: 12px 16px;
  }
}
