.title {
  font-weight: bold;
  color: var(--clr-black-light);
  margin-bottom: 7px;
  display: none;
}

.controls {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.wrap {
  display: flex;
  gap: 8px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  background-color: #fff;
  border: none;
  border-radius: 3px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  padding: 5px;
  color: var(--clr-grey);
  flex-grow: 1;
  min-height: 42px;
}

.btn:hover,
.btn:hover svg {
  color: var(--clr-blue);
}

.back_text,
.forward_text svg,
.play_icon {
  color: var(--clr-grey-dark);
}

.slider {
  display: flex;
  align-items: center;
  gap: 3px;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  padding: 9px;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 50%;
}

.progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(82, 133, 204, 0.35);
}

.hidden_slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

@media screen and (max-width: 1024px) {
  .btn,
  .slider {
    font-size: 12px;
    gap: 0;
  }

  .title {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .controls {
    grid-template-columns: 1fr;
  }
}
