:root {
  --sidebar-width: 240px;
}

.drawer {
  width: var(--sidebar-width);
  margin-top: 64px;
  height: calc(100% - 64px);
}

.sidebar {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
}

.divider {
  margin: 16px;
}
.nav {
  margin-bottom: 16px;
}

.profile {
  text-align: center;
}

.icon {
  font-size: 60px;
}

.list_item {
  display: flex;
  padding: 0;
}

.list_link {
  display: flex;
  align-items: center;
  text-decoration: none;
  flex-grow: 1;
  padding: 5px 10px;
}

.list_icon {
  min-width: 34px;
}

.list_title span {
  font-weight: 500;
  letter-spacing: 0;
  color: var(--clr-black);
}

.skeleton {
  margin: 0 15px;
  height: 44px;
}
