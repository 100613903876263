.active_filter {
  position: relative;
  text-decoration: none;
  padding: 8px 30px 8px 12px;
  font-size: var(--text-size-base);
  color: var(--clr-black);
  background-color: var(--clr-grey-dim-light);
  border-radius: 5px;
  cursor: pointer;
  border: none;
}

.active_filter::after,
.active_filter::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 14px;
  height: 12px;
  width: 2px;
  background-color: var(--clr-blue);
}

.active_filter::after {
  transform: translateY(-50%) rotate(-45deg);
}

.active_filter::before {
  transform: translateY(-50%) rotate(45deg);
}

.active_filter:hover {
  color: var(--clr-black-light);
  text-decoration: line-through;
}

.active_filter:hover::after,
.active_filter:hover::before {
  box-shadow: 0 0 0 0.25px var(--clr-blue);
}
