.grid {
  display: grid;
  grid-template-columns: 1fr 450px;
  grid-gap: 20px;
}

.aside_box {
  border: 1px solid #c2c3c4;
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 4px;
}

.title {
  padding: 20px;
  font-weight: bold;
  border-bottom: 1px solid #c2c3c4;
}

.content {
  padding: 20px;
}

.button {
  display: block;
  margin-top: 20px;
}

.indent {
  margin-bottom: 20px;
}

@media screen and (max-width: 1024px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
