:root {
  /* COLORS */
  --clr-black-light: #303030;
  --clr-black: #333333;

  --clr-grey-dark: rgba(48, 48, 48, 0.72);
  --clr-grey: rgba(48, 48, 48, 0.54);
  --clr-grey-light: rgba(48, 48, 48, 0.38);
  --clr-grey-dim-light: rgba(242, 242, 242, 0.6);

  --clr-blue: #5285cc;
  --clr-blue-dark: rgba(82, 133, 204, 0.14);
  --clr-blue-light: #fcfeff;

  --clr-red: #e00027;
  --clr-green: #2e7d32;
  /* END COLORS */

  /* width */
  --width-container: 1252px;
  --header-height: 70px;
  /* end width */

  /* font size */
  --text-size-extra-small: 12px;
  --text-size-small: 13px;
  --text-size-base: 14px;
  --text-size-medium: 16px;
  --text-size-large: 18px;
  --text-size-huge: 24px;

  --letter-spacing-small: 0.28px;
  --letter-spacing-base: 0.32px;
  /* end font size */
}
