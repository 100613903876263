.box {
  margin-top: 40px;
  box-shadow: 0px 0px 8px rgba(48, 48, 48, 0.12);
  padding: 40px;
  background-color: #fff;
}

.genre {
  font-weight: bold;
}

.info {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 17px;
  margin-bottom: 40px;
}

.caption {
  display: flex;
  gap: 6px;
  align-items: center;
}

.caption span {
  color: var(--clr-black);
}

.title {
  font-size: var(--text-size-base);
  letter-spacing: var(--letter-spacing-small);
  color: var(--clr-black);
  margin: 0;
}

.description p {
  color: var(--clr-black);
  letter-spacing: var(--letter-spacing-small);
  line-height: 22px;
}

.icon {
  font-size: 16px;
  color: var(--clr-grey);
}

@media screen and (max-width: 1024px) {
  .box {
    margin-top: 12px;
    box-shadow: 0px 2px 5px rgba(48, 48, 48, 0.05);
    padding: 16px;
  }
}

@media screen and (max-width: 768px) {
  .box {
    margin: 0 -15px;
  }

  .info {
    flex-direction: column;
    gap: 19px;
    margin-bottom: 30px;
  }
}
