.progress_bar {
  display: flex;
  align-items: center;
  gap: 6px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  padding: 11px 16px;
}

.slider_box {
  width: 100%;
  height: 5px;
  border-radius: 200px;
  background: rgba(48, 48, 48, 0.38);
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.hidden_slider {
  width: 100%;
  margin: 0;
  position: absolute;
  top: -5px;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
}

.progress {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 5px;
  background-color: #5285cc;
  border-radius: 200px;
}

.time {
  color: var(--clr-grey-dark);
}
