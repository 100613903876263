.wrap {
  margin: 14px 0;
  scroll-snap-type: none;
}

.text {
  font-size: var(--text-size-extra-small);
  color: #929292;
  text-decoration: none;
}

a.text:hover {
  text-decoration: underline;
}

.icon {
  font-size: 20px;
  color: #e0e0e0;
}

.wrap ol {
  overflow-x: auto;
  flex-wrap: nowrap;
  white-space: nowrap;
  scroll-snap-type: inline mandatory;
}

.wrap ol li:nth-child(2n) {
  margin: 0 5px;
}

.wrap ol::-webkit-scrollbar {
  display: none;
}
