.content {
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
  padding: 40px 0;
}

.grid {
  display: grid;
  grid-template-columns: 511px 1fr;
  grid-gap: 100px;
}

.preview img {
  height: auto;
}

@media screen and (max-width: 1199px) {
  .grid {
    grid-gap: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .grid {
    grid-template-columns: 1fr;
  }

  .preview {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .content {
    background-color: inherit;
    box-shadow: unset;
    padding: 1px 0 18px;
  }
}
