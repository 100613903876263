.spinner {
  color: #fff;
  margin-left: 10px;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
}
